import request from '@/utils/request'

export function getTransChecks() {
  return request({
    method: 'get',
    url: 'trans-check'
  })
}

export function getTransCheckDetail(transOrderId) {
  return request({
    method: 'get',
    url: `trans-check/${transOrderId}`
  })
}

export function finishTransCheck(transOrderId) {
  return request({
    method: 'post',
    url: `trans-check/${transOrderId}`
  })
}

export function getTransCheckRecords(params) {
  return request({
    method: 'get',
    url: 'trans-check/record',
    params
  })
}

export function getTransCheckRecordDetail(transOrderId) {
  return request({
    method: 'get',
    url: `trans-check/record/${transOrderId}`
  })
}

export function transGoodsAcceptConfirm(data) {
  return request({
    method: 'post',
    url: `trans-check/confirm`,
    data
  })
}
