<template>
  <div>
    <my-nav-bar
      title="调货核对"
      left-text="返回"
      left-arrow
      right-text="核对记录"
      @click-right="toRecordView"
    />
    <div class="app-main-container list">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="(transOrder, index) in list" :key="index" class="accept-item" @click="toDetailView(transOrder.id)">
        <van-cell :title="`${transOrder.from_store.name}→${transOrder.to_store.name}`" :value="transOrder.created_at" />
        <van-cell :title="`${transOrder.kind}种商品`" :value="`共￥${transOrder.cost_amount}`" />
      </div>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getTransChecks } from '@/api/trans_check'
export default {
  components: { myNavBar },
  data() {
    return {
      list: [],
      showEmpty: false
    }
  },
  created() {
    getTransChecks().then(res => {
      this.list = res.data
      this.showEmpty = this.list.length === 0
    })
  },
  methods: {
    toDetailView(transOrderId) {
      this.$router.push({
        path: '/trans-check-detail',
        query: {
          trans_order_id: transOrderId
        }
      })
    },
    toRecordView() {
      this.$router.push({
        path: '/trans-check-record'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .accept-item {
    margin-bottom: 10px;
  }
  .list {
    padding: 10px 10px 0 10px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
</style>
